function hexToArrayBuffer(hex) {
    const bytes = new Uint8Array(Math.ceil(hex.length / 2));
    for (let i = 0; i < bytes.length; i += 1) {
      bytes[i] = parseInt(hex.substr(i * 2, 2), 16);
    }
    return bytes.buffer;
  }
  
  async function decrypt(encryptedHex) {
    const keyHex = process.env.REACT_APP_ENCRYPTION_KEY;
    const ivHex = process.env.REACT_APP_ENCRYPTION_IV;
  
    const keyBuffer = hexToArrayBuffer(keyHex);
    const ivBuffer = hexToArrayBuffer(ivHex);
  
    const key = await crypto.subtle.importKey("raw", keyBuffer, { name: "AES-CBC" }, false, [
      "decrypt",
    ]);
  
    const encryptedBuffer = hexToArrayBuffer(encryptedHex);
  
    const decryptedBuffer = await crypto.subtle.decrypt(
      { name: "AES-CBC", iv: ivBuffer },
      key,
      encryptedBuffer
    );
  
    const decryptedText = new TextDecoder().decode(decryptedBuffer);
  
    return decryptedText;
  }
  
  export default decrypt;